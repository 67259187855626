<template>
  <div class="risk-control-measures">
    <NavBar
      title="风控措施"
      left-arrow
      fixed
      @click-left="$router.go(-1)"
      placeholder
    />
    <div class="rcm-title-list">
      <span
        @click="changeTabActive(0)"
        class="anchor"
        :class="{ 'tab-active': tabActive[0] }"
      >
        <i class="icon icon1"></i><br />
        合规运营
      </span>
      <span
        @click="changeTabActive(1)"
        class="anchor"
        :class="{ 'tab-active': tabActive[1] }"
      >
        <i class="icon icon2"></i><br />
        银行存管
      </span>
      <span
        @click="changeTabActive(2)"
        class="anchor"
        :class="{ 'tab-active': tabActive[2] }"
      >
        <i class="icon icon3"></i><br />
        风险控制
      </span>
      <span
        @click="changeTabActive(3)"
        class="anchor"
        :class="{ 'tab-active': tabActive[3] }"
      >
        <i class="icon icon4"></i><br />
        信息安全
      </span>
    </div>
    <div class="rcm-content-list">
      <ul>
        <li
          :class="{ 'tab-active': tabActive[0] }"
          class="rcm-content rcm-content1"
        >
          <img src="./img/content1-img1.png" alt="" class="content1-img1" />
          <div class="data-list">
            <span class="data-option">
              <i class="icon icon1"></i><br />
              {{ info.online_time }}<br />
              <span class="gray">运营天数（天）</span>
            </span>
            <span class="data-option">
              <i class="icon icon2"></i><br />
              {{ info.total_amounts }}<br />
              <span class="gray">累计交易（亿元）</span>
            </span>
            <span class="data-option">
              <i class="icon icon3"></i><br />
              {{ info.total_users }}<br />
              <span class="gray">累计注册（万人）</span>
            </span>
          </div>
          <div class="text">
            <img src="./img/content1-img2.png" alt="" class="content1-img2" />
          </div>
        </li>
        <li
          :class="{ 'tab-active': tabActive[1] }"
          class="rcm-content rcm-content2"
        >
          <img src="./img/content2-bg.png" alt="" class="content2-bg" />
        </li>
        <li
          :class="{ 'tab-active': tabActive[2] }"
          class="rcm-content rcm-content3"
        >
          <img src="./img/content3-bg.png" alt="" class="content3-bg" />
        </li>
        <li
          :class="{ 'tab-active': tabActive[3] }"
          class="rcm-content rcm-content4"
        >
          <img src="./img/content4-bg.png" alt="" class="content4-bg" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { NavBar, Notify } from 'vant'
import { mapActions } from 'vuex'

export default {
  name: 'RiskControlMeasures',
  components: {
    NavBar,
  },
  data() {
    return {
      tabActive: [true, false, false, false],
      info: {
        online_time: '',
        total_users: '',
        total_invests: '',
        total_amounts: '',
        total_gains: '',
        project_loans: '',
      },
    }
  },
  mounted() {
    // let query = this.$route.query;
    // 请求
    this.requestData()
  },
  methods: {
    ...mapActions(['requestGuideData']),
    changeTabActive(key) {
      this.noActive()
      this.$set(this.tabActive, key, true)
    },
    noActive() {
      for (let i = 0; i < this.tabActive.length; i += 1) {
        this.$set(this.tabActive, i, false)
      }
    },
    leave() {
      let messagingIframe = null
      messagingIframe = document.createElement('iframe')
      messagingIframe.style.display = 'none'
      document.documentElement.appendChild(messagingIframe)
      messagingIframe.src = 'app/close/click'
    },
    // 接口 投资开户数据
    requestData() {
      this.requestGuideData()
        .then((response) => {
          this.info = response
          this.info.total_amounts =
            Math.round((this.info.total_amounts / 100000000) * 100) / 100
          this.info.total_users =
            Math.round((this.info.total_users / 10000) * 100) / 100
        })
        .catch((error) => {
          Notify({
            type: 'warning',
            message: error.message || error,
          })
        })
    },
  },
}
</script>
<style scoped>
.risk-control-measures {
  background: #fff;
}
.rcm-title-list {
  text-align: center;
}
.rcm-title-list .anchor {
  display: inline-block;
  padding: 24px 0 16px 0;
  width: 24%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #9999;
}
.rcm-title-list .icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rcm-title-list .icon1 {
  background-image: url(./img/gray1.png);
}
.rcm-title-list .icon2 {
  background-image: url(./img/gray2.png);
}
.rcm-title-list .icon3 {
  background-image: url(./img/gray3.png);
}
.rcm-title-list .icon4 {
  background-image: url(./img/gray4.png);
}
.rcm-title-list .tab-active .icon1 {
  background-image: url(./img/light1.png);
}
.rcm-title-list .tab-active .icon2 {
  background-image: url(./img/light2.png);
}
.rcm-title-list .tab-active .icon3 {
  background-image: url(./img/light3.png);
}
.rcm-title-list .tab-active .icon4 {
  background-image: url(./img/light4.png);
}
.rcm-title-list .anchor.tab-active {
  color: #171717;
}
.rcm-content-list .rcm-content {
  font-size: 12px;
  line-height: 30px;
  color: #171717;
  display: none;
}
.rcm-content-list .rcm-content.tab-active {
  color: #f03611;
  display: block;
}
.rcm-content1 .content1-img1 {
  width: 100%;
  height: auto;
}
.rcm-content1 .data-list {
  padding: 16px 0;
  text-align: center;
}
.rcm-content1 .data-option {
  width: 32%;
  display: inline-block;
  color: #171717;
  line-height: 26px;
  font-size: 16px;
}
.rcm-content1 .data-option .icon {
  width: 46px;
  height: 46px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.rcm-content1 .data-option .gray {
  color: #999;
  font-size: 12px;
}
.rcm-content1 .data-option .icon1 {
  background-image: url(./img/content1-group1-img1.png);
}
.rcm-content1 .data-option .icon2 {
  background-image: url(./img/content1-group1-img2.png);
}
.rcm-content1 .data-option .icon3 {
  background-image: url(./img/content1-group1-img3.png);
}
.rcm-content1 .content1-img2 {
  width: 100%;
  height: auto;
}
.rcm-content-list .content2-bg,
.rcm-content-list .content3-bg,
.rcm-content-list .content4-bg {
  width: 100%;
  height: auto;
}
</style>
